import React from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import ContentWrapper from "../components/ContentWrapper/ContentWrapper"

const KarieraPage = () => {
  return (
    <Layout>
      <SEO title="Kontakt" />
      <ContentWrapper></ContentWrapper>
    </Layout>
  )
}

export default KarieraPage
